import React, {PropsWithChildren} from 'react';
import BaseInput from '../FormElements/Checkbox';
import {useField} from 'react-final-form';

type CheckboxProps = PropsWithChildren<{
  required?: boolean;
  name: string;
  id: string;
}>;

export default function Checkbox({
  required,
  name,
  id,
  children,
  ...props
}: CheckboxProps) {
  const {input, meta} = useField(name, {type: 'checkbox'});

  const error = meta.error || meta.submitError;

  return (
    <BaseInput id={id} required={required} error={error} {...input} {...props}>
      {children}
    </BaseInput>
  );
}
