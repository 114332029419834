import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';
import DatePicker from 'react-datepicker';
import {useField} from 'react-final-form';
import {Label} from '../Typography';
import {registerLocale} from 'react-datepicker';
import {lighten} from 'polished';
import it from 'date-fns/locale/it';
import {Helper} from '../FormElements';
import {addMinutes, setHours, setMinutes} from 'date-fns';
registerLocale('it', it);

interface DatetimepickerProps {
  name: string;
  label: string;
  help?: string;
  required?: boolean;
  type?: 'datetime' | 'date' | 'time';
  minTime?: string | Date;
}

export default function Datetimepicker({
  name,
  label,
  help,
  required,
  type = 'datetime',
  minTime,
}: DatetimepickerProps) {
  const {input, meta} = useField(name);

  const error = meta.error || meta.submitError;

  const customFormat = () => {
    if (type === 'datetime') {
      return 'dd MMMM yyyy, H:mm';
    }

    if (type === 'time') {
      return 'H:mm';
    }

    return 'dd MMMM yyyy';
  };

  const minTimeFormatted = minTime
    ? addMinutes(new Date(minTime), 15)
    : setHours(setMinutes(new Date(), 30), 8);

  // set the initial value when we have a minTime passed
  useEffect(() => {
    if (!minTime || input.value) {
      return;
    }

    input.onChange(addMinutes(new Date(minTime), 30));
  }, [minTime, input]);

  return (
    <>
      <Label>
        {label} {required && <span> *</span>}
      </Label>
      {help && <Helper>{help}</Helper>}
      <DatePickerContainer error={!!error}>
        <DatePicker
          error={error}
          name={name}
          locale="it"
          timeInputLabel="Orario:"
          showTimeSelectOnly={type === 'time'}
          showTimeSelect={type === 'datetime' || type === 'time'}
          showYearDropdown={type !== 'time'}
          minTime={minTimeFormatted}
          timeIntervals={15}
          maxTime={setHours(setMinutes(new Date(), 30), 19)}
          selected={input.value}
          dateFormat={customFormat()}
          onChange={(value) => input.onChange(value)}
          required={required}
          autoComplete="off"
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </DatePickerContainer>
    </>
  );
}

const DatePickerContainer = styled.div<{error?: boolean}>`
  margin-bottom: 24px;
  > div {
    width: 100%;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    font-size: 15px;
    font-weight: 400;
    font-family: ${(props) => props.theme.typography.main};
    line-height: 16px;
    padding: 7px 12px;
    color: ${(props) => props.theme.colors.bodyText};
    border-radius: 8px;
    background-color: #fff;
    border: solid 2px transparent;
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
    outline: none;

    &:focus {
      border-color: ${(props) => props.theme.colors.main};
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  ${(props) =>
    props.error &&
    css`
      input {
        border-color: ${(props) => props.theme.colors.error};
      }
    `}
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
