import React from 'react';
import styled, {css} from 'styled-components';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {useField} from 'react-final-form';
import {Label} from '../Typography';
import {Helper} from '../FormElements';
import {lighten} from 'polished';

const defaultMaskOptions = {
  prefix: '',
  suffix: ' €',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

interface PriceInputProps {
  name: string;
  label: string;
  help?: string;
  required?: boolean;
}

export default function PriceInput({
  name,
  label,
  help,
  required,
  ...props
}: PriceInputProps) {
  const {input, meta} = useField(name);

  const error = meta.error || meta.submitError;

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  return (
    <InputField>
      <Label>
        {label}
        {required && <span> *</span>}
      </Label>
      {help && <Helper>{help}</Helper>}
      <InputText
        mask={currencyMask}
        required={required}
        {...input}
        {...props}
        placeholder="0.00 €"
        onKeyDown={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputField>
  );
}

const InputText = styled(MaskedInput)<{error?: boolean}>`
  box-sizing: border-box;
  width: 100%;
  font-size: 34px;
  font-weight: 400;
  font-family: ${(props) => props.theme.typography.main};
  line-height: 1;
  padding: 16px;
  color: ${(props) => props.theme.colors.bodyText};
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;

  &:focus {
    border-bottom: 1px solid
      ${(props) => lighten(0.1, props.theme.colors.border)};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${(props) =>
    props.error &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.error};
    `}

  @media ${(props) => props.theme.responsive.phone} {
    border-bottom: 1px solid #d3d3d3;
  }
`;

const InputField = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
