import {lighten} from 'polished';
import React, {ChangeEvent, useState} from 'react';
import {useField} from 'react-final-form';
import styled, {css} from 'styled-components';
import {Label} from '../Typography';

interface RadioOption {
  label: string;
  value: string | number;
}

interface RadioButtonsProps {
  label?: string;
  required?: boolean;
  name: string;
  help?: string;
  disabled?: boolean;
  options: RadioOption[];
  initialValue?: string | number;
}

const RadioButtons = ({
  label,
  required,
  name,
  help,
  disabled,
  options,
  initialValue,
}: RadioButtonsProps) => {
  const {input} = useField(name, {type: 'checkbox'});
  const [checked, setChecked] = useState(initialValue);

  // const error = meta.error || meta.submitError;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
    input.onChange(e.target.value);
  };

  return (
    <RadioButtonsContainer>
      <Label>
        {label}
        {required && <span> *</span>}
      </Label>
      <Buttons>
        {options.map((o: RadioOption) => (
          <CustomLabel
            htmlFor={o.label.replaceAll(' ', '-')}
            $selected={checked && checked === o.value}
          >
            <input
              type="radio"
              id={o.label.replaceAll(' ', '-')}
              name={name}
              value={o.value}
              onChange={(e) => handleChange(e)}
              checked={checked && checked === o.value}
            />
            {o.label}
          </CustomLabel>
        ))}
      </Buttons>
    </RadioButtonsContainer>
  );
};

export default RadioButtons;

const RadioButtonsContainer = styled.div`
  width: 100%;
`;
const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CustomLabel = styled.label<{$selected?: boolean}>`
  flex: 1;
  padding: ${(props) => props.theme.layout.frontendSpace};
  background-color: #fff;
  border: 2px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.4;

  input {
    display: none;
  }

  &:not(:first-child) {
    margin-left: 12px;
  }

  ${(props) =>
    props.$selected &&
    css`
      border: 2px solid ${(props) => props.theme.colors.success};
    `};
`;
