import React, {useEffect, useState} from 'react';
import BaseInput, {InputProps} from '../FormElements/Input';
import {useField} from 'react-final-form';
import styled from 'styled-components';
import slugify from 'slugify';
import {Label} from '../Typography';
import {Helper} from '../FormElements';

interface SlugInputProps extends InputProps {
  initialValue?: string;
}

export default function SlugInput({
  required,
  label,
  help,
  name,
  initialValue,
  placeholder,
  ...rest
}: SlugInputProps) {
  const {input, meta} = useField(name);
  const [prefix, setPrefix] = useState('');

  const error = meta.error || meta.submitError;

  const changeSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(
      slugify(e.target.value, {
        remove: /[*+~.()'"!:@]/g,
        lower: true,
        strict: true,
        trim: false,
      })
    );
  };

  useEffect(() => {
    if (!!initialValue) {
      input.onChange(
        slugify(initialValue, {
          remove: /[*+~.()'"!:@]/g,
          lower: true,
          strict: true,
        })
      );
    }
  }, [initialValue]);

  useEffect(() => {
    setPrefix(window.location.origin);
  }, []);

  return (
    <SlugInputContainer>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required && <span> *</span>}
        </Label>
      )}
      {help && <Helper>{help}</Helper>}
      <Content>
        {prefix && <Preview>{prefix}/</Preview>}
        <BaseInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeSlug(e)}
          value={input.value}
          error={error}
          required={required}
          placeholder={placeholder}
          name={name}
          {...rest}
        />
      </Content>
    </SlugInputContainer>
  );
}

const SlugInputContainer = styled.div`
  input {
    border-radius: 0 8px 8px 0;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-top: 0;
    align-self: stretch;
  }
`;

const Preview = styled.div`
  padding: 5px 12px;
  border-radius: 4px 0 0 4px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid ${(props) => props.theme.colors.ymGreyLight};
  border-right: none;
  margin-top: -24px;
  font-size: 16px;
  margin-bottom: 20px;
  height: 39px;
  color: ${(props) => props.theme.colors.label};
  display: flex;
  align-items: center;

  strong {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
  }
`;
