import React from 'react';
import styled from 'styled-components';
import {TwitterPicker} from 'react-color';
import {useField} from 'react-final-form';
import {Label} from '../Typography';
import {lighten} from 'polished';

interface ColorPickerProps {
  name: string;
  label: string;
  onColorChange?: (color: string) => void;
}

export default function ColorPicker({
  name,
  label,
  onColorChange,
}: ColorPickerProps) {
  const {input, meta} = useField(name);

  const error = meta.error || meta.submitError;

  return (
    <>
      <Label>{label}</Label>
      <Container>
        <TwitterPicker
          colors={[
            '#FF6900',
            '#FCB900',
            '#7BDCB5',
            '#00D084',
            '#8ED1FC',
            '#0693E3',
            '#EB144C',
            '#F78DA7',
            '#9900EF',
            '#000000',
          ]}
          color={input.value}
          onChangeComplete={(value) => {
            onColorChange && onColorChange(value.hex);
            input.onChange(value.hex);
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    </>
  );
}

export const Container = styled.div`
  display: inline-block;
  width: auto;
  position: relative;
  padding-top: 0;

  > div {
    margin-right: 0 !important;
    margin-bottom: 24px;
    box-shadow: none !important;
    width: 280px !important;
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)} !important;
    @media ${(props) => props.theme.responsive.phone} {
      width: 100% !important;
    }

    > div:first-child,
    > div:nth-child(2) {
      display: none !important;
    }
  }

  @media ${(props) => props.theme.responsive.phone} {
    > div > div > span > div {
      width: 25px !important;
      height: 25px !important;
    }

    input {
      width: 80px !important;
    }
  }
`;
const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
