import React from 'react';
import {Select as BaseSelect, SelectProps} from '../Admin/FormElements/Select';
import {useField} from 'react-final-form';

export default function Select(props: FinalFormField<SelectProps>) {
  const {input, meta} = useField(props.name);

  const error = meta.error || meta.submitError;

  return <BaseSelect error={error} {...input} {...props} value={input.value} />;
}
