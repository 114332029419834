import React from 'react';
import BaseInput from '../FormElements/Input';
import {useField} from 'react-final-form';

interface InputProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  help?: string;
  list?: string;

  type:
    | 'text'
    | 'number'
    | 'password'
    | 'email'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'url'
    | 'tel'
    | 'color'
    | 'range'
    | 'search'
    | 'file'
    | 'hidden'
    | 'image'
    | 'submit'
    | 'reset'
    | 'button'
    | 'checkbox'
    | 'radio';
}

export default function Input({name, ...props}: InputProps) {
  const {input, meta} = useField(name, {
    type: props.type,
  });

  const error = meta.error || meta.submitError;

  return <BaseInput error={error} {...input} {...props} />;
}
