import React from 'react';
import {ImageUploader as BaseImageUploader} from '../FormElements';
import {useField} from 'react-final-form';
import {ImageUploaderProps} from '../FormElements/ImageUploader';

interface ImageUploaderFinalFormProps extends ImageUploaderProps {
  name: string;
}

export default function ImageUploader(props: ImageUploaderFinalFormProps) {
  const {
    input: {value, ...input},
    meta,
  } = useField(props.name);

  const error = meta.error || meta.submitError;

  return <BaseImageUploader error={error} {...input} {...props} />;
}
