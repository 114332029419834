import React from 'react';
import BaseInput from '../FormElements/Input';
import {useField} from 'react-final-form';

export default function FileInput(props) {
  const {
    input: {value, onChange, ...input},
    meta,
  } = useField(props.name);

  const error = meta.error || meta.submitError;

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target.files); // instead of the default target.value
  };

  return (
    <BaseInput onChange={handleChange} error={error} {...input} {...props} />
  );
}
