import React from 'react';
import styled, {css} from 'styled-components';
import {Multiselect} from 'react-widgets';
import {useField} from 'react-final-form';
import {Label} from '../Typography';
import {lighten} from 'polished';

interface MultiSelectOption {
  value: string;
  text: string;
}

interface MultiSelectProps {
  data: MultiSelectOption[];
  label: string;
  error?: string;
  required?: boolean;
}

export default function MultiSelect({
  name,
  data,
  label,
  required,
}: FinalFormField<MultiSelectProps>) {
  const {input, meta} = useField(name);

  let error = meta.error || meta.submitError;

  const handleChange = (val: MultiSelectOption[]) => {
    const formatedVals = val.map((v) => v.value);
    input.onChange(formatedVals);
  };

  return (
    <>
      <Label>
        {label} {required && <span> *</span>}
      </Label>
      <MultiselectContainer error={error}>
        <Multiselect
          data={data}
          dataKey="value"
          textField="text"
          value={input.value}
          allowCreate={false}
          onChange={(value) => handleChange(value)}
          messages={{
            emptyList: 'Non ci sono opzioni',
            emptyFilter: 'Nessun opzione corrisponde alla ricerca',
            selectedItems: () => 'Elementi selezionati',
            noneSelected: 'Nessun elemento selezionato',
            removeLabel: 'Rimuovi elemento',
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </MultiselectContainer>
    </>
  );
}

const MultiselectContainer = styled.div<{error?: boolean}>`
  margin-bottom: 20px;
  > div > div {
    border: none;
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
    padding: 0;
    border-radius: 4px;
    height: 39px;
    box-shadow: none;
  }

  > div > div + div {
    border: none;
    padding: 0;
    left: 0;
    right: 0;

    > div {
      margin-bottom: 0;
      left: 0;
      right: 0;
    }
  }

  > div.rw-state-focus > div {
    border-color: ${(props) => props.theme.colors.main} !important;
    box-shadow: none !important;
  }

  ${(props) =>
    props.error &&
    css`
      > div > div {
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    `}
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
