import React from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';
import {Helper} from '../../../FormElements';
import {lighten} from 'polished';
import {Label} from '../../../Typography';

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface SelectProps {
  small?: boolean;
  inline?: boolean;
  label: string;
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  help?: string;
  required?: boolean;
  options: SelectOption[];
}

export const Select = ({
  small,
  inline,
  label,
  error,
  value,
  onChange,
  help,
  required,
  options,
  ...props
}: SelectProps) => {
  return (
    <SelectItem $small={small} $inline={inline} $error={!!error}>
      <Label>
        {label} {required && <span> *</span>}
      </Label>
      {help && <Helper>{help}</Helper>}
      <select onChange={onChange} value={value} required={required} {...props}>
        <option disabled value="">
          Scegli un'opzione
        </option>
        {options &&
          options.map((option, index) => {
            return (
              <option
                key={index}
                value={option.value}
                disabled={option.disabled || false}
              >
                {option.label}
              </option>
            );
          })}
      </select>
      <SelectIcon $small={!!small} $error={!!error} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SelectItem>
  );
};

const SelectItem = styled.div<{
  $error: boolean;
  $small?: boolean;
  $inline?: boolean;
}>`
  position: relative;
  margin-bottom: 20px;

  select {
    appearance: none;
    width: 100%;
    height: 39px;
    border-radius: 5px;
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
    padding: 7px 12px;
    font-size: 16px;
    font-family: ${(props) => props.theme.typography.main};

    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.main};
    }
    &:hover ~ div,
    &:focus ~ div {
      background-color: ${(props) => darken(0.15, props.theme.colors.main)};
    }
    &:focus ~ div {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  ${(props) =>
    props.$error &&
    css`
      select,
      select:focus {
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    `}

  ${(props) =>
    props.$small &&
    css`
      select {
        height: 33px;
        padding: 4px;
        font-size: 13px;
      }
    `}

  ${(props) =>
    props.$inline &&
    css`
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 11px;
      }
    `}
`;

const SelectIcon = styled.div<{$small: boolean; $error: boolean}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background-color: ${(props) => props.theme.colors.main};
  position: absolute;
  right: 0;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
  transition: background-color 500ms;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.colors.buttonText};
    transition: transform 500ms;
  }

  ${(props) =>
    props.$small &&
    css`
      height: 33px;
      width: 33px;
    `}

  ${(props) =>
    props.$error &&
    css`
      border: 1px solid ${(props) => props.theme.colors.error};
      border-left: none;
    `}
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
