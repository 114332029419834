import dynamic from 'next/dynamic';

export {default as Input} from './Input';
export {default as FileInput} from './FileInput';
export {default as MultiSelect} from './MultiSelect';
export {default as ColorPicker} from './ColorPicker';
export {default as Select} from './Select';
export {default as Datetimepicker} from './DateTimePicker';
export {default as ImageUploader} from './ImageUploader';
export {default as Checkbox} from './Checkbox';
export {default as RadioButtons} from './RadioButtons';
export {default as SlugInput} from './SlugInput';
export {default as PriceInput} from './PriceInput';

export const TextEditor = dynamic(() => import('./TextEditor'), {ssr: false});
